@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

table {
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border-bottom: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
